import { Outlet } from "react-router-dom";
import Swal from "sweetalert2";

import { useUser } from "@/client/services/user";
import { PlanType } from "@/client/types";

export const AuthGuard = () => {

  const { user, loading } = useUser();

  if (loading) return null;

  if (user && user.isActive && user.currentPlan !== PlanType.FREE) {
    return <Outlet />;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Swal.fire({
      // eslint-disable-next-line lingui/no-unlocalized-strings
      title: "Login Required",
      // eslint-disable-next-line lingui/no-unlocalized-strings
      text: "You need to login to Winmyjob account to access this page.",
      icon: "warning",
      // eslint-disable-next-line lingui/no-unlocalized-strings
      confirmButtonText: "Ok",
      confirmButtonColor: "#3085d6",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = `${import.meta.env.VITE_WINMYJOB_FE_URL}/login`;
      }
    });
  }
  return null;
};
