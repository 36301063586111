export type Course = {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  sections: Section[];
};

export type Section = {
  id: string;
  courseId: string;
  title: string;
  description: string;
  order: number;
  createdAt: string;
  updatedAt: string;
  courseVideos: CourseVideo[];
};

export type CourseVideo = {
  id: string;
  title: string;
  description: string;
  thumbnailUrl: string;
  videoUrl: string;
};

export type User = {
  id: string;
  name: string | null;
  username: string;
  password: string;
  createdAt: Date;
  updatedAt: Date;
  role: Role;
  isActive: boolean;
  userInfo?: UserInfo | null;
  userPlan?: UserPlan | null;
  userSubscription?: UserSubscription | null;
  currentPlan?: PlanType;
  isSubscriptionCanceled?: boolean;
};

export type UserInfo = {
  id: string;
  userId: string;
  email: string;
  phoneNumber: string;
  avatarUrl: string;
  address: string;
  createdAt: Date;
  updatedAt: Date;
  user: User;
};

export type UserPlan = {
  id: string;
  userId: string;
  planId: string;
  createdAt: Date;
  updatedAt: Date;
  user: User;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  plan: any;
};

export type Plan = {
  id: string;
  name: string;
  description: string;
  stripePriceId?: string | null;
  price: number;
  type: PlanType;
  trialDays?: number | null;
  createdAt: Date;
  updatedAt: Date;
  userPlans: UserPlan[];
  userSubscriptions: UserSubscription[];
  discountPercentage?: number | null;
  discountStartDate?: Date | null;
  discountEndDate?: Date | null;
};

export type UserSubscription = {
  id: string;
  userId: string;
  planId: string;
  stripeCustomerId: string;
  stripeSubscriptionId: string;
  status: SubscriptionStatus;
  createdAt: Date;
  updatedAt: Date;
  user: User;
  plan: Plan;
};

export enum PlanType {
  FREE = "FREE",
  MONTHLY = "MONTHLY",
  ANNUALLY = "ANNUALLY",
}

export enum Role {
  ADMIN = "ADMIN",
  USER = "USER",
}

export enum SubscriptionStatus {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  PAST_DUE = "PAST_DUE",
}

export type Post = {
  id: string;
  content: string;
  images: string[];
  userId: string;
  createdAt: Date;
  updatedAt: Date;
  user: User;
  comments: Comment[];
  likes: PostLike[];
  isLiked: boolean;
  isCommented: boolean;
};

export type Comment = {
  id: string;
  content: string;
  postId: string;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
  user: User;
  post: Post;
};

export type PostLike = {
  id: string;
  postId: string;
  userId: string;
  type: string;
  createdAt: Date;
};
