import { GenerateCoverLetterDto } from "@reactive-resume/dto";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { axios } from "@/client/libs/axios";

export const generateCoverLetter = async (data: GenerateCoverLetterDto) => {
  const response = await axios.post<
    { text: string },
    AxiosResponse<{ text: string }>,
    GenerateCoverLetterDto
  >("/resume/generate-cover-letter", data);

  return response.data;
};

export const useGenerateCoverLetter = () => {
  const {
    error,
    isPending: loading,
    mutateAsync: generateCoverLetterFn,
  } = useMutation({
    mutationFn: generateCoverLetter,
    onSuccess: (data) => {
      return data.text;
    },
  });

  return { generateCoverLetter: generateCoverLetterFn, loading, error };
};
