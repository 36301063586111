import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "@lingui/macro";
import {
  AlertDialog,
  AlertDialogContent,
  Button,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  TextArea,
} from "@reactive-resume/ui";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { useToast } from "@/client/hooks/use-toast";
import { useGenerateCoverLetter } from "@/client/services/resume/generate-cover-letter";

// Define the schema for form validation
const formSchema = z.object({
  skills: z.string().min(1, "Skills are required"),
  jobDescription: z.string().min(1, "Job Description is required"),
});

type FormValues = z.infer<typeof formSchema>;

export const CoverLetterGeneratorDialog = ({
  isOpen,
  onClose,
  onGenerateContent,
}: {
  isOpen: boolean;
  onClose: () => void;
  onGenerateContent: (content: string) => void;
}) => {
  const { toast } = useToast();
  const { generateCoverLetter, loading } = useGenerateCoverLetter();

  const form = useForm<FormValues>({
    defaultValues: {
      skills: "",
      jobDescription: "",
    },
    resolver: zodResolver(formSchema),
  });

  const handleGenerateContent = async (values: FormValues) => {
    try {
      // Replace this with your actual content generation logic
      const generatedContent = await generateCoverLetter(values);
      onGenerateContent(generatedContent.text || "");
      onClose();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast({
        variant: "error",
        title: t`An error occurred while generating the content.`,
        description: error?.message || "",
      });
    }
  };

  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent className="z-50">
        <FormProvider {...form}>
          <div className="grid grid-cols-1 gap-4">
            {/* Skills Input */}
            <FormField
              name="skills"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t`Skills`}</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder={t`Enter your skills`} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Job Description Input */}
            <FormField
              name="jobDescription"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t`Job Description`}</FormLabel>
                  <FormControl>
                    <TextArea {...field} rows={5} placeholder={t`Enter your job description`} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Generate Button */}
            <div className="flex justify-end">
              <Button disabled={loading} onClick={form.handleSubmit(handleGenerateContent)}>
                {t`Generate`}
              </Button>
            </div>
          </div>
        </FormProvider>
      </AlertDialogContent>
    </AlertDialog>
  );
};
