export const DEFAULT_MODEL = "gpt-3.5-turbo";
export const DEFAULT_MAX_TOKENS = 1024

const GENERATE_COVER_LETTER_PROMPT_TEMPLATE = `You are an AI assistant specialized in writing cover letters.
Do not return anything else except the text you generated. It should not begin with a newline. It should not have any prefix or suffix text.
Using the following details, generate a personalized cover letter:

Skills: {skills}
Job Description: {jobDescription}

Cover Letter:
`;
