import { cn } from "@reactive-resume/utils";
import { forwardRef } from "react";

export type TextAreaProps = {
  hasError?: boolean;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, hasError = false, ...props }, ref) => (
    <textarea
      ref={ref}
      autoComplete="off"
      className={cn(
        "flex w-full rounded border bg-transparent px-3 py-1 text-sm ring-0 ring-offset-transparent transition-colors placeholder:opacity-80 hover:bg-secondary/20 focus:border-primary focus:bg-secondary/20 focus-visible:outline-none focus-visible:ring-0 disabled:cursor-not-allowed disabled:opacity-50",
        hasError ? "border-error" : "border-border",
        className,
      )}
      {...props}
    />
  ),
);

TextArea.displayName = "TextArea";
